<template>
    <div class="outerBox">
		<img src="@/assets/common/subtitleYellow.png" class="bgi" alt="">
		<img src="@/assets/common/subtitleIcon.png" class="subtitleIcon" alt="">
		<span class="specialFont">{{specialFont}}</span>
		<span class="subtitleText">{{content}}</span>
		<span class="remark" v-if="remark">{{remark}}</span>
	</div>
</template>

<style lang="less" scoped>
@keyframes scaling {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

.outerBox {
	width: 100%;
	height: 3.7vh;
	position: relative;
	display: flex;
	align-items: center;
	
	
	.bgi {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	.subtitleIcon {
		height: 100%;
		aspect-ratio: 1/1;
		margin-left: 2.86%;
		transform-origin: 50% 50%;
		animation: scaling 1.8s infinite;
	}
	.specialFont {
		height: 100%;
		font-size: 2.77vh;
		display: flex;
		align-items: center;
		font-family: 'yuweij', sans-serif;
		color: #fff;
		text-shadow: 0px 0px 2.14% rgba(255,208,59,0.7);
		font-weight: 500;
		margin-left: 0.95%;
	}
	.subtitleText {
		height: 100%;
		font-size: 1.85vh;
		display: flex;
		align-items: center;
		font-family: 'titleFont', sans-serif;
		color: #fff;
		text-shadow: 0px 0px 9px rgba(255,208,59,0.7);
		font-weight: 400;
	}
	.remark {
		margin-left: 41.67%;
		height: 100%;
		font-size: 1.48vh;
		font-family: 'titleFont', sans-serif;
		display: flex;
		align-items: center;
		color: #fff;
		font-weight: 400;
	}
}
</style>

<script>
export default {
	props:{
		text:{
			type: String
		},
		remark:{
			type: String
		},
		special:{
			type: String
		}
	},
	data(){
		return {
			content: '',
			specialFont: ''
		}
	},
	mounted(){
		this.content = this.text;
		if(this.special){
			this.specialFont = this.special;
		}
		return false;
	}
}
</script>

